import { Card, Text } from "evergreen-ui"
import { Button } from "react-bootstrap"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from 'react-router-dom'
import { useSelector } from "react-redux"

import { usePrevious } from "./../../helpers";

import styles from './UpdateChecker.module.scss'

const hash = (str) => {
  const len = str.length
  let hash = 0
  if (len === 0) return hash
  let i
  for (i = 0; i < len; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0; // Convert to 32bit integer
  }
  return hash
}

const UpdateChecker = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const previousHashes = useRef({})
  const location = useLocation()
  const previousLocation = usePrevious(location, {})
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)

  const checkUpdates = useCallback(async () => {
    const checkFileUpdate = async (url, name) => {
      try {
        const response = await fetch(url)

        if (response.status !== 200) {
          return
        }

        const text = await response.text()
        const newHash = hash(text.replace(/[a-f0-9]{32}/g, ""))
        const previousHash = previousHashes.current[name]

        if (previousHash && previousHash !== newHash) {
          setUpdateAvailable(true)
        }

        previousHashes.current[name] = newHash
      } catch (err) {
        return
      }
    }

    await checkFileUpdate(process.env.PUBLIC_URL, 'app')
    await checkFileUpdate(process.env.PUBLIC_URL + '/config.js', 'config')
  }, [])

  useEffect(() => {
    // Check for updates on load to store the initial hashes
    checkUpdates()

    // Set an interval to check for updates every minute
    const interval = setInterval(checkUpdates, 60000)
    return () => clearInterval(interval)
  }, [checkUpdates])

  useEffect(() => {
    if (updateAvailable) {
      if (previousLocation.pathname !== location.pathname) {
        window.location.reload()
      }
    }
 }, [updateAvailable, location.pathname, previousLocation.pathname])

  return isAuthenticated && updateAvailable && (
    <div className={styles.updateBannerContainer}>
      <Card className={styles.updateBanner} elevation={1} background="tint1">
        <Text>We just released new features, click Update to get them! <span role="img" aria-label="tada">🎉</span></Text>
        <Button
          className={styles.updateButton}
          onClick={() => window.location.reload()}
        >
          Update
        </Button>
      </Card>
    </div>
  )
}

export default UpdateChecker
