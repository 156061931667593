import { get } from './client.js'
import { fetchItemFromLocalStorage, saveItemsToLocalStorage } from '../helpers/localStore'

export function getGlobalUserId() {
  return fetchItemFromLocalStorage("uid", "session")
}

export function getSessionUser() {
  return get('/session/user')
}

export function getCurrentSession() {
  return get('/session')
}

export const setSessionUser = (userObj) => {
  const userName = userObj.first_name + " " + userObj.last_name

  saveItemsToLocalStorage({
    name: userName,
    email: userObj.email_address,
  }, "user-details")
}
