import axios from 'axios'

import { refreshAccessToken } from './auth.js'

export const API_URL = window.GISUAL_API_URL || "https://api.gisual.com";

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Gisual-Frontend-Name': 'phronesis-terminal',
    'X-Gisual-Frontend-Version': process.env.REACT_APP_VERSION,
  },
});

//////////////////
// request methods
//////////////////

export function request(url, method, config, retried = false) {
  const shouldProcessError = config?.shouldProcessError ?? true

  config = config ?? {}

  const withCredentials = config.withCredentials ?? true

  return instance.request(url, {
    'url': url,
    'method': method,
    'data': config.data ?? null,
    'headers': config.headers ?? {},
    'withCredentials': withCredentials,
  })
    .catch(function(error) {
      if (shouldProcessError && !retried) {
        return processError(error, url, method, config)
      } else {
        return Promise.reject(error)
      }
    })
}

export function del(url, config) {
  return request(
    url,
    'DELETE',
    config,
  )
}

export function get(url, config) {
  return request(
    url,
    'GET',
    config,
  )
}

export function patch(url, config) {
  return request(
    url,
    'PATCH',
    config,
  )
}

export function post(url, config) {
  return request(
    url,
    'POST',
    config,
  )
}

export function put(url, config) {
  return request(
    url,
    'PUT',
    config,
  )
}

//////////////////////////
// response error handling
//////////////////////////

export async function processError(error, url, method, config) {
  var errorMsg = error.message;

  if (error.response) {
    if (error.response.status === 401) {
      if (await refreshAccessToken()) {
        return await request(url, method, config, true)
      }
    }

    // Expects RFC-7807 problem details resposes
    errorMsg = error.response.data.title
  } else if (error.request) {
    errorMsg = error.request.statusText
  }

  return Promise.reject({ msg: errorMsg, error })
}
