import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// eslint-disable-next-line
import PrivateRoute from "../common/PrivateRoute";
import { restoreSession } from "../api/auth";

import { ThemeProvider } from "evergreen-ui";

import Header from "../common/header/HeaderComponent";
import UpdateChecker from "../common/update-checker/UpdateChecker";

import styles from "./global.module.css";
import GisualEvergreenTheme from "./GisualEvergreenTheme";

import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;

const ForgotPasswordComponent = React.lazy(() => import("./components/login/ForgotPasswordComponent"));
const ResetPasswordComponent = React.lazy(() => import("./components/login/ResetPasswordComponent"));
const LoginComponent = React.lazy(() => import("./components/login/LoginComponent"));
const LogoutComponent = React.lazy(() => import("./components/login/LogoutComponent"));
const ClaimTaskComponent = React.lazy(() => import("./components/tasks/pages/ClaimTaskComponent"));
const TaskPage = React.lazy(() => import("./components/tasks/pages/TaskPage"));

function AppContainer(props) {
  restoreSession();

  return (
      <Router>
        <div className={styles.AppContainer}>
          <ThemeProvider value={GisualEvergreenTheme}>
            <Header />
            <UpdateChecker />

            <Suspense fallback={<div>Loading...</div>}> {/* It's better to have some fallback content */}
              <Routes>
                <Route path="/tasks/:task_id" element={<PrivateRoute><TaskPage/></PrivateRoute>} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/logout" element={<LogoutComponent />} />
                <Route path="/forgot-password" element={<ForgotPasswordComponent />} />
                <Route path="/reset-forgotten-password/:token" element={<ResetPasswordComponent />} />
                {/*<Route path="/signups/:signup_id" element={<SignUpComponent />} />*/}

                <Route path="/" element={<PrivateRoute><ClaimTaskComponent/></PrivateRoute>} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        </div>
      </Router>
  );
}

export default AppContainer;
