import { recordAnalyticEvent } from "../api/analytics"

const globalStoragePrefix = "gisual"

/**
 * Save a list of items to localStorage with their values
 * @param Object values
 * @param String storePrefix
 */
export function saveItemsToLocalStorage(values, storePrefix = "default", log = false) {
  const storageItemPrefix = `${globalStoragePrefix}.${storePrefix}`

  log && console.log('saveItemsToLocalStorage', storePrefix, values)

  Object.keys(values)
    .forEach((token) => {
      log && console.log(`${globalStoragePrefix}.${storePrefix}.${token}`, values[token])
      log && console.log(`${globalStoragePrefix}.${storePrefix}.${token}`, JSON.stringify(values[token]))

      localStorage.setItem(
        `${storageItemPrefix}.${token}`,
        JSON.stringify(values[token]),
      )
    })
}

/**
 * Fetch one or more items from localStorage
 * @param Array tokens
 * @param String storePrefix
 */
export function fetchItemsFromLocalStorage(tokens, storePrefix = "default", log = false) {
  let tokenReturns = {}

  log && console.log('fetchItemsFromLocalStorage', storePrefix, tokens)

  tokens
    .forEach((token) => {
      tokenReturns[token] = fetchItemFromLocalStorage(token, storePrefix)
    })

  return tokenReturns
}

/**
 * Fetch single item from localStorage
 * @param String token
 * @param String storePrefix
 */
export function fetchItemFromLocalStorage(token, storePrefix = "default", log = false) {
  let value = localStorage.getItem(`${globalStoragePrefix}.${storePrefix}.${token}`)

  log && console.log('fetchItemFromLocalStorage', storePrefix, token)

  log && console.log(`${globalStoragePrefix}.${storePrefix}.${token}`, value)

  try {
    value = JSON.parse(value)
  } catch {}

  // Recover from multi-encoded JSON error
  try {
    if (typeof value === 'string' && value.startsWith('"')) {
      const originalValue = value

      while (typeof value === 'string' && value.startsWith('"')) {
        value = JSON.parse(value)
      }

      recordAnalyticEvent(
        'Recovered from multi-encoded JSON error',
        {
          token,
          storePrefix,
          originalValue,
          recoveredValue: value,
        }
      )

      // Fix the value in the local store
      saveItemsToLocalStorage({ [token]: value }, storePrefix, log)
    }
  } catch {}

  log && console.log(`${globalStoragePrefix}.${storePrefix}.${token}`, value)

  return value
}

/**
 * Remove all permanent storage, a process run on logout
 * or when all tokens have expired.
 * @param Array tokens
 * @param String storePrefix
 */
export function removeItemsFromLocalStorage(tokens, storePrefix = "default", log = false) {
  const storageItemPrefix = `${globalStoragePrefix}.${storePrefix}`

  log && console.log('removeItemsFromLocalStorage', storePrefix, tokens)

  tokens
    .forEach((token) => {
      const storageItemName = `${storageItemPrefix}.${token}`
      localStorage.removeItem(storageItemName)
    })
  return true
}
