import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { userIsAdmin, validateSession } from './../api/auth';

function PrivateRoute({ children, requiresAdmin = false, ...rest }) {
    const location = useLocation();
    const isAuth = validateSession();
    const isAdmin = userIsAdmin();

    // For routes that require admin access
    if (requiresAdmin && !isAdmin) {
        // User is not an admin, redirect to the homepage
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    // For routes that don't require admin access but do require authentication
    if (!requiresAdmin && !isAuth) {
        // User is not authenticated, redirect to the login page
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // User is either an admin or is authenticated (as required by the route)
    return children;
}

// How to use:
/*
<Routes>
  <Route path="/protected" element={
    <PrivateRoute>
      <ProtectedComponent />
    </PrivateRoute>
  }/>
</Routes>
*/

export default PrivateRoute;
