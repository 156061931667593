import React from 'react';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux'
import posthog from 'posthog-js'
import store from './app/store';
import { StrictMode } from 'react';

import AppContainer from './app/App.jsx'

import * as serviceWorker from './serviceWorker';

import './index.css';
import "react-selectize/dist/index.css";

window.Gisual = {
    "version": process.env.REACT_APP_VERSION,
    "environment_name": window.GISUAL_ENVIRONMENT,
    "api_url": window.GISUAL_API_URL,
}

if (!!window.POST_HOG_API_KEY) {
    posthog.init(window.POST_HOG_API_KEY, { api_host: "https://us.posthog.com" })
    posthog.register_once({
        "version": window.Gisual.version,
        "environment": window.Gisual.environment_name
    })
}

createRoot(document.getElementById('app')).render(
    <StrictMode>
      <Provider store={store}>
        <AppContainer />
      </Provider>,
    </StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
