import axios from 'axios'
import posthog from 'posthog-js'

let currentUserTrackingInfo = {};
let isPostHogConfigured = !!window.POST_HOG_API_KEY

async function getUserIP() {
  if (currentUserTrackingInfo && currentUserTrackingInfo.hasOwnProperty("ip")) {
    return currentUserTrackingInfo.ip
  } else {
    try {
      let ipRes = await axios.get("https://api.ipify.org?format=json")
      currentUserTrackingInfo = { ...currentUserTrackingInfo, ...ipRes.data }
      return currentUserTrackingInfo.ip
    } catch (err) {
      return null
    }
  }
}

/**
 * Record or time an event performed by a user, to be sent to PostHog for analysis.
 * @param eventName
 * @param eventData
 */
export async function recordAnalyticEvent(eventName, eventData = {}) {
  if (!isPostHogConfigured) return;

  let userip = await getUserIP()
  eventData = { ...eventData, "current_ip": userip }

  posthog.capture(eventName, eventData);
}

/**
 * Run when a user logs in.
 * @param Object userData
 */
export async function identifyUserInPostHog(user) {
  const { email, orgid, uid, isAdmin } = user
  posthog.identify(uid,
      {
        email,
        'org_id': orgid,
        'is_admin': isAdmin,
        'latest_login': Date.now(),
        'login_ip': await getUserIP(),
      }
  );
}
