import { createSlice } from '@reduxjs/toolkit'

const AuthSlice = createSlice({
  name: 'auth',
  slice: 'auth',
  initialState: {
    "isAuthenticated": false,
    "accessToken": null,
    "refreshToken": null,
    "authExpireTime": null,
  },
  reducers: {
    resetFreshAuthState(state, action) {
      return {
        "isAuthenticated": false,
        "accessToken": null,
        "refreshToken": null,
        "authExpireTime": null,
      }
    },
    setAllAuthState(state, action) {
      let incoming = action.payload
      return incoming
    },
    setNewAccessToken(state, action) {
      let incoming = action.payload
      state.accessToken = incoming

      return state
    },
    setNewAuthExpire(state, action) {
      let incoming = action.payload
      state.authExpireTime = incoming

      return state
    }
  }
})

export const { setAllAuthState, setNewAccessToken, setNewAuthExpire, resetFreshAuthState } = AuthSlice.actions

export default AuthSlice.reducer
