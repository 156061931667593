import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

import { getGlobalUserId } from "../../api/aaa";

import styles from "./Header.module.css";

const HeaderComponent = () => {
    const isAuth = useSelector((state) => state.auth.isAuthenticated)
    const userid = getGlobalUserId()

    return (
        <header>
            <Link to="/" className={styles.logo}>
                Phronesis Terminal
            </Link>
            {isAuth && userid && (
                <Link className={styles.logoutBtn} to='/logout'>
                    Log out
                </Link>
            )}
        </header>
    );
};

export default HeaderComponent;
